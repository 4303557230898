<template>
  <div v-if="$store.state.dashboard.loading">
    <loader></loader>
  </div>
  <div id="dashboard" v-else>
    <div v-if="!dashboardErrors.templateConfiguration">
      <div
        id="progress-mobile"
        class="d-sm-block d-md-none d-lg-none"
        v-if="dashboardData && dashboardData.templateConfiguration && dashboardData.templateConfiguration.showTopHeader"
      >
        <dashboard-progress :progress="dashboardData.percentageComplete"></dashboard-progress>
      </div>
      <div
        class="jumbotron mb-3"
        :style="{ backgroundColor: this.style.subheaderBackgroundColor, color: this.style.subheaderTextColor }"
        v-if="
          !dashboardData.isOnboardingFinished &&
            dashboardData &&
            dashboardData.templateConfiguration &&
            dashboardData.templateConfiguration.showTopHeader
        "
      >
        <div class="container p-0">
          <i
            class="material-icons ml-auto float-right d-block d-md-none d-lg-none pointer"
            v-if="!introductionTextHidden"
            @click="hideIntroductionText"
            >close</i
          >
          <div class="row">
            <div
              class="col-md-8 flex-column justify-content-center"
              :class="{ 'd-none d-md-block d-lg-block': introductionTextHidden, 'd-flex': !introductionTextHidden }"
            >
              <h6
                v-if="!user.isFirstTimeAccess"
                class="title introduction-title font-weight-bold"
                :style="{ color: this.style.welcomeTitleColor }"
              >
                {{ $t('dashboard.welcome', { name: user.firstName }) }}
              </h6>
              <h6
                v-else
                class="title introduction-title font-weight-bold"
                :style="{ color: this.style.welcomeTitleColor }"
              >
                {{ $t('dashboard.welcomeFirstTime', { name: user.firstName }) }}
              </h6>
              <component v-if="todos.length" v-bind:is="introductionText" v-bind="$props" />
            </div>
            <div class="col-md-2 d-flex flex-column justify-content-center">
              <hr
                class="d-block d-md-none d-lg-none my-4"
                v-if="!introductionTextHidden"
                style="margin-left: -5rem; margin-right: -5rem"
              />
              <div
                v-if="!dashboardData.templateConfiguration.disableCounter"
                id="counter"
                :class="dashboardData.isWorking == undefined && 'data-unavailable'"
              >
                <dashboard-counter
                  v-if="dashboardData.isWorking !== undefined"
                  :working="dashboardData.isWorking"
                  :days="Math.abs(dashboardData.calendarDays)"
                />
                <div v-else class="error-message">
                  <h6 class="title font-weight-bold">N/A</h6>
                  <el-tooltip
                    effect="light"
                    :content="$t('dashboard.noContentAvailable')"
                    placement="top"
                    popper-class="error-message-tooltip"
                  >
                    <i class="material-icons">info</i>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="col-md-2 d-none d-md-block">
              <div id="progress">
                <dashboard-progress :progress="dashboardData.percentageComplete"></dashboard-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="jumbotron">
        <div class="container">
          <p>
            {{ 'dashboard.noContentAvailable' | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="row" v-if="dashboardData.isOnboardingFinished">
      <div class="col-sm-12 col-md-12 no-margin-bottom">
        <div class="jumbotron">
          <h2 class="font-weight-bold">
            <span v-if="isOnboarding">{{ 'info.onboardingFinishedHeading' | translate }}</span
            ><span v-else>{{ 'info.onboardingFinishedHeadingOffboarding' | translate }}</span>
          </h2>
          <h3 style="line-height: 140%">
            <span v-if="isOnboarding"> {{ 'info.onboardingFinishedText' | translate }}</span>
            <span v-else> {{ 'info.onboardingFinishedTextOffboarding' | translate }}</span>
          </h3>
        </div>
      </div>
    </div>
    <div class="container" :class="{ 'mt-3': !dashboardData.templateConfiguration }">
      <div>
        <div v-if="$store.state.dashboard.loadingSections">
          <loader></loader>
        </div>
        <!-- Components -->
        <div v-else>
          <div class="row cards" id="dashboard-components" v-if="dashboardData.sections.length > 0">
            <template v-for="section in dashboardData.sections">
              <dashboard-module
                v-if="showModule(section)"
                :title="section.title"
                :titleColor="section.titleColor"
                :width="section.displayWidthType"
                :key="section.id"
                :no-side-padding="noSidePadding(section)"
                :body-class="section.bodyClass"
                :id="section.id"
                :flex="section.type === 9"
                :type="section.type"
              >
                <component :data="section" :is="section.component" />
              </dashboard-module>
            </template>
          </div>
          <div v-else-if="dashboardErrors.sections" class="container">
            <div class="row">
              <div class="card-body d-flex align-items-center justify-content-center">
                <i class="material-icons mr-3"> report_problem </i>
                <span>
                  {{ 'dashboard.noContentAvailable' | translate }}
                </span>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="card-body d-flex align-items-center justify-content-center">
                <span>
                  {{ 'dashboard.noContentToShow' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// Header
import dashboardCounter from '@app/components/dashboard/Counter';
import dashboardProgress from '@app/components/dashboard/Progress';

// Module Wrapper
import dashboardModule from '@app/components/ui/Module';

//Components
import dashboardCompanyPage from '@app/components/dashboard/CompanyPage';
import dashboardContacts from '@app/components/dashboard/Contacts';
import dashboardDidYouKnow from '@app/components/dashboard/DidYouKnow';
import dashboardForm from '@app/components/dashboard/Form';
import dashboardIntroduction from '@app/components/dashboard/Introduction';
import dashboardLearningPage from '@app/components/dashboard/LearningPage';
import dashboardPulseCheck from '@app/components/dashboard/PulseCheck';
import dashboardQuiz from '@app/components/dashboard/Quiz';
import dashboardVideo from '@app/components/dashboard/Video';
import tasks from '@app/mixins/tasks';
import planhatMixin from '@app/mixins/planhat';
import { getVapidDetails } from '@app/http/notification';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { registerServiceWorkerNotifications } from '@app/notifications';

export default {
  mixins: [tasks, planhatMixin],
  components: {
    dashboardCompanyPage,
    dashboardContacts,
    dashboardCounter,
    dashboardDidYouKnow,
    dashboardForm,
    dashboardIntroduction,
    dashboardLearningPage,
    dashboardModule,
    dashboardProgress,
    dashboardPulseCheck,
    dashboardQuiz,
    dashboardVideo
  },
  created() {
    document.body.classList.add('is-on-dashboard');
    setTimeout(() => this.$store.dispatch('dashboard/getTodoData'), 200);
    if (!window.localStorage.getItem('fingerprint')) {
      getFingerprint().then((fingerprint) => window.localStorage.setItem('fingerprint', fingerprint));
    }

    if (!window.localStorage.getItem('publicKeyNotifications')) {
      getVapidDetails().then((e) => {
        e.json().then((result) => {
          window.localStorage.setItem('publicKeyNotifications', result.publicKey);
        });
      });
    }
  },
  mounted() {
    this.$store.dispatch('settings/getFeaturesWithConsent', this.user);
    // show legacyform reminder
    if (!window.localStorage.formShown && this.dashboardData) {
      if (!this.formsV2Enabled && this.dashboardData.formTodoLink && this.dashboardData.formTodoLink.length > 0) {
        const unfinishedTodos = this.dashboardData.formTodoLink.filter((todo) => !todo.isFinished);
        if (unfinishedTodos && unfinishedTodos.length > 0) {
          const { id, title } = unfinishedTodos.sort((a, b) =>
            a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 0
          )[0];
          this.showFormReminder(title, `/form/${id}`);
        }
      }

      // show formv2 reminder
      if (this.formsV2Enabled && this.dashboardData.formsV2ToDo && this.dashboardData.formsV2ToDo.length > 0) {
        const unfinishedTodos = this.dashboardData.formsV2ToDo.filter((todo) => !todo.isFinished);

        if (unfinishedTodos && unfinishedTodos.length > 0) {
          const { formId, learningPageId, moduleId, formName } = unfinishedTodos.sort((a, b) =>
            a.formName.toLowerCase() < b.formName.toLowerCase() ? -1 : 0
          )[0];
          this.showFormReminder(formName, `/form/${moduleId}/${learningPageId}/${formId}`);
        }
      }
    }

    registerServiceWorkerNotifications();
  },
  computed: {
    ...mapGetters('auth', ['user', 'isAdmin']),
    ...mapGetters('settings', ['style']),
    ...mapGetters('dashboard', ['dashboardErrors', 'isOnboarding']),
    introductionText() {
      const translationIndex = this.todos.length == 1 ? 1 : 0;
      const template =
        '<span>' +
        this.$t(
          'dashboard.tasks',
          {
            taskCount:
              `<span id="dashboard-taskcount"><router-link :to="{name: 'tasks'}" class="font-weight-bold" style="text-decoration: underline;color:` +
              this.style.tasksLinkColor +
              '">' +
              this.$t('dashboard.taskCount', { count: this.todos.length }, translationIndex) +
              '</router-link></span>'
          },
          translationIndex
        ) +
        '</span>';
      const noDataAvailable = `<span>` + this.$t('dashboard.noContentAvailable') + `</span>`;
      return {
        template: !this.dashboardErrors.todoList ? template : noDataAvailable,
        props: this.$options.props
      };
    }
  },
  data() {
    return {
      introductionTextHidden: window.localStorage.hideIntroductionText
    };
  },
  methods: {
    hideIntroductionText() {
      this.introductionTextHidden = true;
      window.localStorage.hideIntroductionText = true;
    },
    noSidePadding(section) {
      return (
        (section.type === 4 && section.displayWidthType > 1) ||
        section.type === 1 ||
        section.type === 6 ||
        section.type === 2 ||
        section.type === 13
      );
    },
    showModule(section) {
      return (
        (section.type !== 4 && section.type !== 5 && section.type !== 7 && section.type !== 8) || section.items.length
      );
    },
    showTodoReminder(title, path) {
      this.$alert(this.$t('form.newreminder', { title }), {
        showConfirmButton: true,
        closeOnClickModal: true,
        closeOnPressEscape: true,
        customClass: 'small',
        confirmButtonText: this.$t('task.reminder.action.tasks'),
        callback: (action) => {
          window.localStorage.formShown = true;
          if (action === 'confirm') {
            this.$router.push('/tasks');
          }
        }
      });
    },
    showFormReminder(title, path) {
      this.$alert(this.$t('form.newreminder', { title }), {
        showConfirmButton: true,
        closeOnClickModal: true,
        closeOnPressEscape: true,
        customClass: 'small',
        confirmButtonText: this.$t('task.reminder.action.tasks'),
        callback: (action) => {
          window.localStorage.formShown = true;
          if (action === 'confirm') {
            this.$router.push('/tasks');
          }
        }
      });
    },
    trackPlanhat() {
      if (this.dashboardData.percentageComplete === 100) {
        if (!localStorage.getItem('percentageComplete')) {
          localStorage.setItem('percentageComplete', 100);
          this.track('tal_100_percent_complete');
        } else {
          return;
        }
      }
    }
  },
  beforeDestroy() {
    document.body.classList.remove('is-on-dashboard');
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_font-sizes.scss';

#dashboard-header {
  .col-md-4 {
    max-height: 220px;
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  &.row {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .card-module {
    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }

    .card {
      flex-grow: 1;
    }
  }
}

#dashboard {
  background: white;
  padding-bottom: 1rem;
}

#dashboard .data-unavailable {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .error-message {
    position: relative;
    @media (max-width: 767px) {
      margin-bottom: 2rem;
    }
    h6 {
      font-size: 2rem;
    }
    i {
      max-width: none;
      position: absolute;
      top: -18px;
      right: -20px;
    }
  }
}
#dashboard .jumbotron {
  background: #fafafa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 0;

  @media (min-width: 768px) {
    padding: 1.5rem 5rem;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 1.5rem 3rem 1rem;
  }
  @media (max-width: 575px) {
    padding: 1.5rem 0.5rem 1rem;
  }
}
#dashboard .jumbotron .introduction-title {
  font-size: var(--title-size);
}

#dashboard .jumbotron .container {
  max-width: 1240px;
}

@media (max-width: 767px) {
  .jumbotron {
    .introduction-title {
      margin-bottom: 1rem;
    }
    .introduction-text,
    .introduction-title {
      text-align: left;
    }
  }
  #dashboard .container {
    padding: 0 1rem;
    max-width: unset;

    .row {
      margin-left: 0;
      margin-right: 0;
      .col-sm-4,
      .col-md-6,
      .col-md-12 {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .panel {
      padding: 0px;
    }
  }
}

#dashboard .jumbotron .container {
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 768px) {
    padding: 0;
  }
}
#dashboard-components {
  &.is-table-row {
    flex-flow: row wrap;

    .col-md-6,
    .col-md-12 {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    @media (max-width: 768px) {
      .col-md-6,
      .col-md-12 {
        margin: 0;
      }

      .card-body {
        padding: 0 15px 0 15px !important;
      }
    }
  }
}
</style>
